import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";
import Cookies from 'js-cookie';
import { Cookie, SiteAuth } from "../../constants";
import { onAppInit } from "../common/thunks";

const initialState = {
  isLoading: true,
  token: null,
  isTempUser: null,
  sessionId: uuidv4(),
  sitePasswordValidated: false
};

const userIdentifierToken = "";

export const onAuth0LoadingCompleted = createAsyncThunk(
  "chat/onAuth0LoadingCompleted",
  async ({ isAuthenticated, getAccessTokenFn }) => {
    const token = isAuthenticated ? await getAccessTokenFn() : userIdentifierToken;
    return {
      isAuthenticated,
      token,
    };
  }
);

export const onSitePasswordValidated = createAsyncThunk(
  "chat/onSitePasswordValidated",
  () => {
    Cookies.set(Cookie.SiteAuth, SiteAuth.isAuthenticated);
    return;
  }
)

const authSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(onAuth0LoadingCompleted.pending, (state) => {
      state = initialState;
    });

    builder.addCase(onAuth0LoadingCompleted.fulfilled, (state, { payload }) => {
      const { isAuthenticated, token } = payload;
      state.token = token;
      state.isTempUser = !isAuthenticated;
      state.isLoading = false;
    });

    builder.addCase(onAuth0LoadingCompleted.rejected, (state) => {
      state.token = userIdentifierToken;
      state.isTempUser = true;
      state.isLoading = false;
    });

    builder.addCase(onSitePasswordValidated.fulfilled, (state) => {
      state.sitePasswordValidated = true;
    });

    builder.addCase(onAppInit.fulfilled, (state, { payload }) => {
      const { sitePasswordValidated } = payload;
      state.sitePasswordValidated = sitePasswordValidated;
    });
  },
});

export default authSlice.reducer;
