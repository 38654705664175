import { useEffect, useRef, useState } from "react";

// Updates the height of a <textarea> when the value changes.
export const useAutosizeTextArea = (
  textAreaRef,
  value,
  minHeight,
  onTextAreaHeightChange
) => {
  const [height, setHeight] = useState();
  const prevHeight = useRef();

  useEffect(() => {
    if (textAreaRef) {
      textAreaRef.style.height = "0px";
      const scrollHeight = textAreaRef.scrollHeight;
      const newHeight = Math.max(scrollHeight, minHeight) + "px";
      textAreaRef.style.height = newHeight;
      setHeight(newHeight);
    }
  }, [textAreaRef, value, minHeight]);

  useEffect(() => {
    if (height !== prevHeight.current) {
      onTextAreaHeightChange();
    }

    prevHeight.current = height;
  }, [height, onTextAreaHeightChange]);
};
