import { configureStore } from '@reduxjs/toolkit'
import authReducer from './redux/auth/slice';
import audioTrackReducer from './redux/audioTrack/slice';
import chatReducer from './redux/chat/slice';
import uiReducer from './redux/ui/slice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    audioTrack: audioTrackReducer,
    chat: chatReducer,
    ui: uiReducer
  },
});
