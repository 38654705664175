import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ResponseMode } from "../../constants";
import { onAudioPanelOpen } from "../audioTrack/slice";
import { onAppInit, onResponseModeToggled } from "../common/thunks";
import { sendIMessageContactInfo, updateIntroOnboardingStatus, getIntroOnboardingStatus } from "../../api";

export const onTourGuideCompleted = createAsyncThunk(
  "ui/onTourGuideCompleted",
  (_, { getState }) => {
    const state = getState();
    const token = state.auth.token;
    const sessionId = state.auth.sessionId;
    updateIntroOnboardingStatus(token, sessionId);

    return {
      hasCompletedTourGuide: true,
    };
  }
);

export const fetchTourGuideStatus = createAsyncThunk(
  "ui/fetchTourGuideStatus",
  async (_, { getState }) => {
    const state = getState();
    const token = state.auth.token;
    const sessionId = state.auth.sessionId;

    const user = await getIntroOnboardingStatus(token, sessionId);

    return {
      hasCompletedTourGuide: user.metadata.intro_onboarded,
    };
  }
);

export const onIMessageContactInfoSubmitted = createAsyncThunk(
  "ui/onIMessageContactInfoSubmitted",
  (contactInfo, { getState }) => {
    const state = getState();
    const token = state.auth.token;
    const sessionId = state.auth.sessionId;
    return sendIMessageContactInfo(token, sessionId, contactInfo);
  }
)

const uiSlice = createSlice({
  name: "ui",
  initialState: {
    responseMode: ResponseMode.Audio,
    hasCompletedTourGuide: false,
    audioPanel: {
      hasInitiaized: false,
      isOpen: false,
      activeTrackId: null,
      isPlaying: false,
      trackMetadataById: {}
    }
  },
  reducers: {
    onAudioPanelSwipedRight: (state) => {
      state.audioPanel.isOpen = false;
      state.audioPanel.activeTrackId = null;
    },
    onPlayTrackToggled: (state, { payload }) => {
      const { trackId } = payload;

      if (state.audioPanel.activeTrackId === trackId) {
        state.audioPanel.isPlaying = !state.audioPanel.isPlaying;
      } else {
        state.audioPanel.activeTrackId = trackId;
        state.audioPanel.isPlaying = true;

        if (!state.audioPanel.trackMetadataById[trackId]) {
          state.audioPanel.trackMetadataById[trackId] = {
            id: trackId,
            currentTime: 0,
            duration: 0
          };
        }
      }
    },
    onTrackCurrentTimeUpdated: (state, { payload }) => {
      const { trackId, time } = payload;
      if (!state.audioPanel.trackMetadataById[trackId]) {
        state.audioPanel.trackMetadataById[trackId] = {
          id: trackId,
          currentTime: time,
          duration: 0
        };
      }

      state.audioPanel.trackMetadataById[trackId] = {
        ...state.audioPanel.trackMetadataById[trackId],
        currentTime: time
      };
    },
    onTrackDurationUpdated: (state, { payload }) => {
      const { trackId, duration } = payload;
      if (!state.audioPanel.trackMetadataById[trackId]) {
        state.audioPanel.trackMetadataById[trackId] = {
          id: trackId,
          currentTime: 0,
          duration: duration
        };
      }
      state.audioPanel.trackMetadataById[trackId] = {
        ...state.audioPanel.trackMetadataById[trackId],
        duration
      };
    }
  },
  extraReducers: (builder) => {
    builder.addCase(onAudioPanelOpen.pending, (state) => {
      state.audioPanel.isOpen = true;
    });

    builder.addCase(onResponseModeToggled.pending, (state) => {
      state.responseMode =
        state.responseMode === ResponseMode.Audio
          ? ResponseMode.Text
          : ResponseMode.Audio;
    });

    builder.addCase(onAppInit.fulfilled, (state, { payload }) => {
      state.hasInitiaized = true;
      state.hasCompletedTourGuide = payload.hasCompletedTourGuide;
    });

    builder.addCase(onTourGuideCompleted.fulfilled, (state, { payload }) => {
      state.hasCompletedTourGuide = payload.hasCompletedTourGuide;
    });

    builder.addCase(fetchTourGuideStatus.fulfilled, (state, { payload }) => {
      state.hasCompletedTourGuide = payload.hasCompletedTourGuide;
    });
  }
});

export const {
  onAudioPanelSwipedRight,
  onPlayTrackToggled,
  onTrackCurrentTimeUpdated,
  onTrackDurationUpdated
} = uiSlice.actions;

export default uiSlice.reducer;
