import React, { useState } from "react";
import { LandingPageContainer } from "../components/LandingPageContainer";
import { TextField } from "../components/common/TextField";
import Button from "../components/common/Button";

import { useDispatch } from "react-redux";
import { onIMessageContactInfoSubmitted } from "../redux/ui/slice";

import "./iMessageRedirect.scss";

const EmailContact = "pearl@thepearl.xyz";
const buttonContactText = "contact";
const errorMessage = "Please enter valid phone number/email";

const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const phoneRegex =
  /^(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})$/;

export const IMessageRedirect = () => {
  document.body.classList.remove("dark-theme");

  const dispatch = useDispatch();

  const [contactInfo, setContactInfo] = useState("");
  const [isInvalidInfo, setIsInvalidInfo] = useState(false);

  const onInfoChange = (value) => {
    setIsInvalidInfo(false);
    setContactInfo(value);
  };

  const handleOnConnectClicked = () => {
    const isValidEmail = emailRegex.test(contactInfo);
    const isValidPhoneNumber = phoneRegex.test(contactInfo);

    if (!isValidEmail && !isValidPhoneNumber) {
      setIsInvalidInfo(true);
      return;
    }

    dispatch(onIMessageContactInfoSubmitted(contactInfo));
    window.location.href = encodeURI("imessage://thepearl@imsg.chat&body=Hello, how should I engage with the Pearl") + "%3F";
  };

  const handleOnContactClicked = () => {
    window.location.href = `mailto:${EmailContact}`;
  };

  return (
    <LandingPageContainer>
      <div className="imessage-redirect__input-container">
        <TextField
          type="text"
          name="info"
          placeholder="PHONE NUMBER/iCLOUD EMAIL"
          value={contactInfo}
          onChange={onInfoChange}
        />
        <div className="imessage-redirect__button">
          <Button
            variant="secondary"
            showBorder={true}
            onClick={handleOnConnectClicked}
          >
            connect
          </Button>
        </div>
        {isInvalidInfo && (
          <div className="imessage-redirect__error">{errorMessage}</div>
        )}
      </div>
      <div className="home__contact-button">
        <Button
          variant="primary"
          showBorder={false}
          onClick={handleOnContactClicked}
        >
          {buttonContactText}
        </Button>
      </div>
    </LandingPageContainer>
  );
};
