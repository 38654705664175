import React from 'react';
import { TourGuideParagraph } from '../TourGuideParagaph';

export const Segment7 = () => (
    <div>
        <TourGuideParagraph>
            THE PEARL™ offers Organic AI™ (Animal Instinct) to move people away from Artificial experiences and 'Intelligence' as the dominant societal modality for healing the suffering of being a human ‘being.’
        </TourGuideParagraph>
        <TourGuideParagraph>
            An instrument of navigation, THE PEARL™ finely tunes 750 million years of evolution like the strings of a violin and draws inspiration from nature's great wisdom.
        </TourGuideParagraph>
    </div>
);
