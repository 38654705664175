import React from 'react';

export const TourGuideImage = ({ src, footnote, children }) => (
    <div className='tour-guide__image'>
        <div className='tour-guide__image-container'>
            {
                src ? <img src={src} alt={'PEARL'}/> : children
            }
        </div>
        {footnote && (<div className='tour-guide__image-footnote'>{footnote}</div>)}
    </div>
);
