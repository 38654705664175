import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Spinner } from "./Spinner";
import { Message } from "../components/Message";
import {
  selectChatMessages,
  selectHasUnloadedHistory,
  selectIsFetching,
  selectIsFetchingMoreHistory
} from "../redux/chat/selectors";

import { onRequestMoreConversationHistory } from "../redux/chat/slice";
import { LoadingDots } from "./LoadingDots";
import { MessageInputField } from "./MessageInputField";

import "./messageContainer.scss";

const ScrollTopDetector = () => {
  const dispatch = useDispatch();
  const isFetching = useSelector(selectIsFetching);
  const isFetchingMoreHistory = useSelector(selectIsFetchingMoreHistory);
  const hasUnloadedHistory = useSelector(selectHasUnloadedHistory);

  const prevIsAtTop = useRef();

  useEffect(() => {
    const onScroll = () => {
      const isAtTop = document.documentElement.scrollTop === 0;

      if (
        !isFetching &&
        !isFetchingMoreHistory &&
        hasUnloadedHistory &&
        isAtTop &&
        prevIsAtTop.current === false
      ) {
        dispatch(onRequestMoreConversationHistory());
      }

      prevIsAtTop.current = document.documentElement.scrollTop === 0;
    };

    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, [dispatch, hasUnloadedHistory, isFetching, isFetchingMoreHistory]);

  return null;
};

const useScrollTop = (containerRef) => {
  const dispatch = useDispatch();
  const isFetching = useSelector(selectIsFetching);
  const isFetchingMoreHistory = useSelector(selectIsFetchingMoreHistory);
  const hasUnloadedHistory = useSelector(selectHasUnloadedHistory);

  const prevIsAtTop = useRef();

  useEffect(() => {
    const handleScroll = () => {
      // Access the scrollTop property of the containerRef
      const scrollTopValue = containerRef.current.scrollTop;
      const isAtTop = scrollTopValue === 0;

      if (
        !isFetching &&
        !isFetchingMoreHistory &&
        hasUnloadedHistory &&
        isAtTop &&
        prevIsAtTop.current === false
      ) {
        dispatch(onRequestMoreConversationHistory());
      }

      prevIsAtTop.current = containerRef.current.scrollTop === 0;
    };

    if (containerRef.current) {
      containerRef.current.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, [dispatch, hasUnloadedHistory, isFetching, isFetchingMoreHistory]); // Empty dependency array to run the effect only once during component mount
}

export const MessageContainer = () => {
  const isFetching = useSelector(selectIsFetching);
  const isFetchingMoreHistory = useSelector(selectIsFetchingMoreHistory);
  const messages = useSelector(selectChatMessages);
  const hasContent = !!messages.length;

  const latestMessageId = messages.length ? messages[messages.length - 1].id : undefined;
  const prevLatestMessageId = useRef();

  const oldestMessageId = messages.length ? messages[0].id : undefined;
  const prevOldestMessageId = useRef();

  useEffect(() => {
    if (latestMessageId !== prevLatestMessageId.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
    if (prevOldestMessageId.current && oldestMessageId !== prevOldestMessageId.current) {
      const oldMessageElement = document.getElementById(`message-${prevOldestMessageId.current}`);
      containerRef.current.scrollTop = oldMessageElement?.getBoundingClientRect().y - 24;
    }

    prevLatestMessageId.current = latestMessageId;
    prevOldestMessageId.current = oldestMessageId;
  }, [latestMessageId, oldestMessageId]);

  const containerRef = useRef(null);
  useScrollTop(containerRef);

  const handleOnUserTyping = () => {
    containerRef.current.scrollTop = containerRef.current.scrollHeight;
  };


  return (
    <div ref={containerRef} id={"message-container"} className="message-container">
      <ScrollTopDetector />
      <div className="message-container__messages">
        {isFetchingMoreHistory && (
          <div className="message-container__loading-dots">
            <LoadingDots />
          </div>
        )}
        {messages.map((message) => (
          <Message key={message.id} message={message} />
        ))}
      </div>
      {isFetching && !hasContent && <Spinner />}
      {isFetching && hasContent && (
        <div className="message-container__loading-dots">
          <LoadingDots />
        </div>
      )}
      <MessageInputField onUserTyping={handleOnUserTyping} />
    </div>
  );
};
