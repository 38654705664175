import React from "react";
import cn from "classnames";

import "./message.scss";

export const Message = ({ message }) => {
  const messageClassNames = cn("message", {
    "message--user": message.source === "user",
    "message--assistant": message.source === "assistant",
  });

  return (
    <div id={`message-${message.id}`} className={messageClassNames}>
      <div className="message__content-container">
        <p>{message.content}</p>
      </div>
    </div>
  );
};
