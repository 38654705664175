import React from 'react';
import { Col } from "reactstrap"
import { PearlLogo } from '../PearlLogo';

import './landingPageContainer.scss';

const copyRightText = "Copyright ©2024 Animuse Health All rights reserved.";
const disclaimerText = "Our app provides educational and informational content only. It is not intended to be a substitute for consultation, diagnosis, treatment or other professional medical advice from a healthcare professional, nor is it designed to provide you with a medical diagnosis, treatment or other medical services.";

export const LandingPageContainer = ({
    showLogo = true,
    showDisclaimerText = false,
    children
}) => (
    <div className="landing-page">
        <div className="landing-page__header">
            THE PEARL™
        </div>
        <Col className="landing-page__element-container">
            {showLogo && <PearlLogo className="landing-page__pearl-logo"/>}
            {children}
        </Col>
        <div className="landing-page__footer">
          {showDisclaimerText && (
            <div className="landing-page__disclaimer">
                {disclaimerText}
            </div>
          )}
          <div className="landing-page__copyright">
            {copyRightText}
          </div>
        </div>
      </div>
);
