import React from "react";
import { useDispatch } from "react-redux";
import { TourGuideParagraph } from "../TourGuideParagaph";
import { onTourGuideCompleted } from "../../../redux/ui/slice";
import history from "../../../utils/history";
import Button from "../../../components/common/Button";

export const Segment13 = () => {
  const dispatch = useDispatch();

  const handleTourGuideCompletion = () => {
    dispatch(onTourGuideCompleted());
    navigator.mediaDevices
      .getUserMedia({ video: false, audio: true })
      .then((mediaStream) => {
        mediaStream.getTracks().forEach((track) => track.stop());
        history.push("/chat");
      })
      .catch(() => {});
  };

  return(
    <div className="tour-guide__segment9">
    <TourGuideParagraph>
      To speak to THE PEARL™ click the allow mic button below.
    </TourGuideParagraph>
    <div className="tour-guide__mic-button">
      <Button onClick={handleTourGuideCompletion} variant="secondary-white">
          allow mic
      </Button>
    </div>
  </div>
  );
};