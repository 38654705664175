import React from 'react';
import Button from '../common/Button';

const EmailContact = "pearl@thepearl.xyz";
const buttonText = "CONTACT";

export const ContactButton = () => {
    const handleOnContactClicked = () => {
        window.location.href = `mailto:${EmailContact}`;
      };

    return (
        <Button
          variant="secondary-white"
          showBorder
          onClick={handleOnContactClicked}>
          {buttonText}
        </Button>
    )
}