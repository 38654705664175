export const ResponseMode = {
    Audio: 'Audio',
    Text: 'Text'
};

export const MessageSource = {
    User: 'user',
    Assistant: 'assistant'
};

export const Cookie = {
    SiteAuth: 'SiteAuth',
    HasCompletedTourGude: 'TourGuide'
};

export const SiteAuth = {
    isAuthenticated: '99',
    isNotAuthenticated: '0'
};

export const TourGuideCompletion = {
    Incompleted: '0',
    Completed: '1'
}
