import React from "react";

export const Send = ({ size, color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 39 39"
    fill={color}
  >
    <circle cx="19.6781" cy="19.0771" r="19" fill="#131313" />
    <path
      d="M18.6781 28.0771C18.6781 28.6294 19.1258 29.0771 19.6781 29.0771C20.2304 29.0771 20.6781 28.6294 20.6781 28.0771L18.6781 28.0771ZM20.3852 10.37C19.9947 9.97952 19.3615 9.97952 18.971 10.37L12.607 16.734C12.2165 17.1245 12.2165 17.7577 12.607 18.1482C12.9976 18.5387 13.6307 18.5387 14.0212 18.1482L19.6781 12.4914L25.335 18.1482C25.7255 18.5387 26.3586 18.5387 26.7492 18.1482C27.1397 17.7577 27.1397 17.1245 26.7492 16.734L20.3852 10.37ZM20.6781 28.0771L20.6781 11.0771L18.6781 11.0771L18.6781 28.0771L20.6781 28.0771Z"
      fill="#DEDEDE"
    />
  </svg>
);
