export const Pulse = ({ size }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 255 256"
    fill="none"
  >
    <ellipse cx="127.5" cy="128" rx="127.5" ry="128" fill="#1F1F1F" />
    <ellipse
      cx="127.5"
      cy="128"
      rx="109.5"
      ry="110"
      fill="#DEDEDE"
      fillOpacity="0.02"
    />
    <ellipse cx="127.5" cy="128" rx="94.5" ry="95" fill="#1E1E1E" />
    <ellipse
      cx="127.5"
      cy="128"
      rx="78.5"
      ry="79"
      fill="#DEDEDE"
      fillOpacity="0.09"
    />
    <ellipse cx="127.5" cy="128" rx="63.5" ry="64" fill="#1E1E1E" />
  </svg>
);
