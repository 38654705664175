import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { getTrack, getTrackRecommendations } from "../../api";

export const onNewTrackRecieved = createAsyncThunk(
  "audioTrack/onNewTrackRecieved",
  async (_, { getState }) => {
    const state = getState();
    const token = state.auth.token;
    const sessionId = state.auth.sessionId;
    const { trackIds, trackDataById } = state.audioTrack;
    const trackIdsToFetch = trackIds.filter((id) => !trackDataById[id]);
    const trackData = await Promise.all(
      trackIdsToFetch.map((id) => getTrack(token, sessionId, id))
    );
    return trackData;
  }
);

export const onAudioPanelOpen = createAsyncThunk(
  "audioTrack/onAudioPanelOpen",
  async (_, { getState }) => {
    const state = getState();
    const token = state.auth.token;
    const sessionId = state.auth.sessionId;
    const tracks = await getTrackRecommendations(token, sessionId);
    return tracks;
  }
);

const audioTrackSlice = createSlice({
  name: "chat",
  initialState: {
    tracks: [],
    isFetching: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(onNewTrackRecieved.pending, (state) => {
      state.isFetching = true;
    });

    builder.addCase(onNewTrackRecieved.fulfilled, (state, { payload }) => {
      payload.forEach(
        ({ track_id, title, album, artist, source, transcript }) => {
          state.trackDataById[track_id] = {
            id: track_id,
            title,
            album,
            artist,
            source,
            transcript,
          };
        }
      );

      state.isFetching = false;
    });

    builder.addCase(onAudioPanelOpen.pending, (state) => {
      state.isFetching = true;
    });

    builder.addCase(onAudioPanelOpen.fulfilled, (state, { payload }) => {
      state.tracks = payload.map(
        ({ track_id, title, album, artist, source }) => ({
          id: track_id,
          title,
          album,
          artist,
          source,
        })
      );

      state.isFetching = false;
    });
  },
});

export default audioTrackSlice.reducer;
