import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { onNewTextMessageSubmitted } from "../../redux/chat/slice";
import { selectIsFetching } from "../../redux/chat/selectors";
import { onResponseModeToggled } from "../../redux/common/thunks";
import Button from "../common/Button";
import { useAutosizeTextArea } from "./useAutoSizeTextArea";
import { Pearl } from "../../icons/Pearl";
import { Send } from "../../icons/Send";

import "./messageInputField.scss";
import { getIsDesktopBrowser } from "../../utils/navigator";

export const MessageInputField = ({ onUserTyping }) => {
  const isDesktop = getIsDesktopBrowser();
  const dispatch = useDispatch();

  const isFetching = useSelector(selectIsFetching);
  const [message, setMessage] = useState("");

  const textAreaElement = useRef(null);

  useAutosizeTextArea(textAreaElement.current, message, 35, onUserTyping);

  const handleOnActionButtonClicked = () => {
    if (isFetching) return;

    if (message) {
      handleOnSubmit();
    } else {
      dispatch(onResponseModeToggled());
    }
  };

  const handleOnSubmit = () => {
    if (!message.trim().length) return;

    dispatch(onNewTextMessageSubmitted(message));
    setMessage("");
  };

  useEffect(() => {
    if (textAreaElement.current && !isFetching) {
      textAreaElement.current.focus();
    }
  }, [isFetching]);

  const handleOnUserTyping = (event) => {
    setMessage(event.target.value);
    onUserTyping();
  };

  const handleOnKeyDown = (event) => {
    if (isDesktop && event.code === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleOnSubmit();
    }
  };

  return (
    <div className="message-input-field">
      <textarea
        ref={(element) => (textAreaElement.current = element)}
        className="message-input-field__input"
        type="text"
        name="message"
        value={message}
        disabled={isFetching}
        placeholder="Say hi, or speak your mind"
        onChange={handleOnUserTyping}
        onKeyDown={handleOnKeyDown}
      />
      <div className="message-input-field__button">
        <Button
          onClick={handleOnActionButtonClicked}
          disabled={isFetching}
        >
          {
            message ? <Send size={32} color="white" /> : <Pearl size={32} color="white" />
          }
        </Button>
      </div>
    </div>
  );
};
