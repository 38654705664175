import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import Button from "./common/Button";
import { onSitePasswordValidated } from '../redux/auth/slice';

import "./sitePasswordGate.scss";

const passwords = ["thisoneisforyou", "calving", "brydong", "animuseVIPbeta", "Theworldisyouroyster2024"];

const errorMessage = 'Incorrect password!';

export const SitePasswordGate = () => {
  const dispatch = useDispatch();

    const [password, setPassword] = useState('');
    const [hasError, setHasError] = useState(false);

    const onPasswordFormSubmit = e => {
        e.preventDefault();
        if (passwords.includes(password)) {
          dispatch(onSitePasswordValidated());
        } else {
          setHasError(true);
        }
      };

    return (
        <>
            <div className="home__password-container">
              <form onSubmit={onPasswordFormSubmit}>
                <div className="home__password-input-container">
                  <input className="home__password-input" type="password" name="password" placeholder="PASSWORD" value={password} onChange={e => setPassword(e.target.value)}></input>
                </div>
                <Button variant="secondary" showBorder={true} type="submit">
                    ENTER
                </Button>
                {
                  hasError && <div className='site-password-gate__error'>{errorMessage}</div>
                }
              </form>
            </div>
          </>
    )
};