import { MessageSource } from "../../constants";

export const selectChatMessages = (state) => state.chat.messages;

export const selectIsFetching = (state) => state.chat.isFetching;

export const selectIsInitiated = (state) => state.chat.isInitiated;

export const selectIsFetchingMoreHistory = (state) => state.chat.isFetchingMoreHistory;

export const selectlastAssistantMessage = (state) => {
    return state.chat.messages.findLast(message => message.source === MessageSource.Assistant)
};

export const selectHasUnloadedHistory = (state) => state.chat.hasUnloadedHistory;
