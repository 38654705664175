import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from "react-redux";
import { onTourGuideCompleted } from "../../redux/ui/slice";
import * as Segments from './segments';
import { LoadingDots } from "../../components/LoadingDots";
import "./tourGuide.scss";
import history from "../../utils/history";
import Button from '../../components/common/Button';


const {Segment1, Segment2, Segment3, Segment4, Segment5, Segment6, Segment7, Segment8, Segment9, Segment10, Segment11,Segment12,Segment13} = Segments;

const segmentSize = Object.keys(Segments).length;

const keyToTourGuideSegmentMap = {
    1: { component: Segment1, isText: true },
    2: { component: Segment2, isText: false },
    3: { component: Segment3, isText: true },
    4: { component: Segment4, isText: false },
    5: { component: Segment5, isText: true },
    6: { component: Segment6, isText: false},
    7: { component: Segment7, isText: true },
    8: { component: Segment8, isText: false},
    9: { component: Segment9, isText: true },
    10: { component: Segment10, isText: false},
    11: { component: Segment11, isText: true },
    12: { component: Segment12, isText: false},
    13: { component: Segment13, isText: true }
};

export const TourGuide = () => {
    document.body.classList.add("dark-theme");
    const scrollRef = useRef();
    const [ renderedSegmentKeys, setRenderedSegmentKeys ] = useState([]);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ userScrolling, setUserScrolling ] = useState(false);
    const [ longPressed, setLongPressed ] = useState(false);
    const dispatch = useDispatch();

    const handleOnSkipClicked = () => {
        dispatch(onTourGuideCompleted());
        navigator.mediaDevices
          .getUserMedia({ video: false, audio: true })
          .then((mediaStream) => {
            mediaStream.getTracks().forEach((track) => track.stop());
            history.push("/chat");
          })
          .catch(() => {});
    }

    const getSegmentTime = () => { 
        const currentSegmentKey = [...renderedSegmentKeys].pop(); 
        return keyToTourGuideSegmentMap[currentSegmentKey].isText ? 4000 : 2000; 
    };

    useEffect(() => {
        const handleScroll = () => {
          if (scrollRef.current) {
            if (window.scrollY + window.innerHeight < scrollRef.current.scrollHeight) {
                setUserScrolling(true);
            } else {
                setUserScrolling(false);
            }
          }
        };
    
        window.addEventListener('scroll', handleScroll);
    
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        // scroll to bottom whenever a new segment is rendered and user hasn't long clicked or scrolled yet 
        if (!longPressed && !userScrolling && scrollRef.current) {
            window.scrollTo(0, scrollRef.current.scrollHeight);
        }
        
        if (renderedSegmentKeys.length >= segmentSize) {
            setIsLoading(false);
            return;
        }

        if (!renderedSegmentKeys.length) {
            setTimeout(() => {
                setRenderedSegmentKeys([1]);
            }, 800); // delay for background colour transition
            return;
        }

        setIsLoading(true);
        const intervalId = setInterval(
            () => {
                setRenderedSegmentKeys((keys) => {
                    keys.push(keys.length + 1);
                    return [...keys];
                });
            },
            getSegmentTime() // render new text segment every 4 seconds and new image segment every 2 seconds
        );
        
        return () => clearInterval(intervalId);
    }, [renderedSegmentKeys]);

    function handleMouseDown() {
        // Set longPressed to true immediately when mouse is pressed
        setLongPressed(true);
    }

    return (
        <div ref={scrollRef}  className="tour-guide" onMouseDown={handleMouseDown}>
            <div className='tour-guide__top-bar'>
                <div className='tour-guide__skip-button-container'>
                    <Button 
                    variant="primary-white" 
                    showBorder={false} 
                    onClick={handleOnSkipClicked}
                    >
                    skip
                    </Button>
                </div>
            </div>
            <div className="tour-guide__segment-container">
                {
                    renderedSegmentKeys.map(key => {
                        const Segment = keyToTourGuideSegmentMap[key].component;
                        return <Segment key={key} />;
                    })
                }
                { isLoading && <LoadingDots /> }
            </div>
        </div>
    );
};