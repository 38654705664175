import React, { useEffect } from "react";
import {Router, Route, Switch } from "react-router-dom";

import Home from "./views/Home";
import Profile from "./views/Profile";
import Chat from "./views/Chat";
import { useAuth0 } from "@auth0/auth0-react";
import history from "./utils/history";

// styles
import "./App.css";

// fontawesome
import initFontAwesome from "./utils/initFontAwesome";
import { useDispatch, useSelector } from "react-redux";
import { onAuth0LoadingCompleted } from "./redux/auth/slice";
import { Spinner } from "./components/Spinner";
import { onAppInit } from "./redux/common/thunks";
import { selectHasInitiaized } from "./redux/ui/selectors";
import { TourGuide } from "./views/TourGuide";
import LogIn from "./views/LogIn";
import { IMessageRedirect } from "./views/IMessageRedirect";

initFontAwesome();

const App = () => {
  const dispatch = useDispatch();

  const hasInitiaized = useSelector(selectHasInitiaized);

  const { isLoading, error, isAuthenticated, getAccessTokenSilently } =
    useAuth0();

  useEffect(() => {
    if (!isLoading) {
      dispatch(
        onAuth0LoadingCompleted({
          isAuthenticated,
          getAccessTokenFn: isAuthenticated && getAccessTokenSilently,
        })
      );
    }
  }, [dispatch, isAuthenticated, isLoading, getAccessTokenSilently]);

  useEffect(() => {
    dispatch(onAppInit());
  }, [dispatch]);

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return <Spinner />;
  }

  if (hasInitiaized) {
    history.replace(isAuthenticated ? '/home' : '/');
  }

  return (
    <Router history={history}>
      <div id="app" className="d-flex flex-column h-100">
        <Switch>
            <Route path="/" exact component={LogIn} />
            <Route path="/chat" component={Chat} />
            <Route path="/profile" component={Profile} />
            <Route path="/tour-guide" component={TourGuide} />
            <Route path="/home" component={Home} />
            <Route path="/imessage-redirect" component={IMessageRedirect} />
          </Switch>
      </div>
    </Router>
  );
};

export default App;
