import React from 'react';
import Spline from '@splinetool/react-spline';

import './pearlLogo.scss';

export const PearlLogo = ({ onLoad, className, children }) => (
    <div className={`pearl-logo ${className}`}>
        <Spline 
            style={{width: '280px', height: '280px'}}
            onLoad={onLoad}
            scene="https://prod.spline.design/bCRunN-o9HtTn8tp/scene.splinecode" />
        {children}
    </div>
);