import React from 'react';
import { TourGuideParagraph } from '../TourGuideParagaph';

export const Segment5 = () => (
    <div>
        <TourGuideParagraph>
            THE PEARL™ works like an internal compass and is designed to help you better navigate the feelings and emotions that govern the inner landscapes of your life.
        </TourGuideParagraph>
    </div>
);
