export const selectAudioPanelIsOpen = (state) => state.ui.audioPanel.isOpen;

export const selectTrackIsPlaying = (state, trackId) =>
  state.ui.audioPanel.activeTrackId === trackId &&
  state.ui.audioPanel.isPlaying;

export const selectActiveTrackMetadata = (state) => {
  const { activeTrackId, trackMetadataById } = state.ui.audioPanel;
  if (!activeTrackId) return null;
  return trackMetadataById[activeTrackId];
};

export const selectTrackMetadata = (state, trackId) =>
  state.ui.audioPanel.trackMetadataById[trackId];

export const selectIsTrackPlaying = (state) => state.ui.audioPanel.isPlaying;

export const selectResponseMode = (state) => state.ui.responseMode;

export const selectHasInitiaized = (state) => state.ui.hasInitiaized;

export const selectHasCompletedTourGuide = (state) => state.ui.hasCompletedTourGuide;
