import Axios from "axios";

function createHeaders(token, sessionId, contentType) {
  if (!contentType) {
    contentType = "application/json";
  }
  let headers = {
    "Content-Type": contentType,
  };
  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }
  if (sessionId) {
    headers["X-Animuse-Session-ID"] = sessionId;
  }
  return headers;
}

function createReqConfig(token, sessionId) {
  return { headers: createHeaders(token, sessionId) };
}

export const sendMessageText = async (token, sessionId, message) => {
  const config = createReqConfig(token, sessionId);

  const body = {
    "message": message
  };

  const response = await Axios.post("api/chat/text", body, config);
  return response.data;
};

export const sendMessageVoice = async (token, sessionId, message) => {
  const body = {
    'message': message
  };

  let headers = createHeaders(token, sessionId);
  const response = await fetch(
    "api/chat/voice",
    {
      method: "POST",
      headers: headers,
      body: JSON.stringify(body)
    }
  )
  return response.body.getReader();
};

export const sendVoice = async (token, sessionId, blob) => {
  let headers = createHeaders(token, sessionId, blob.type);
  const response = await fetch(
    "api/chat/voice2",
    {
      method: "POST",
      headers: headers,
      body: blob
    }
  )
  return response;
};


export const getMessageHistory = async (token, sessionId, oldestMessageId) => {
  const config = createReqConfig(token, sessionId);
  if (oldestMessageId) {
    config["params"] = {
      page_key: oldestMessageId
    };
  }
  const response = await Axios.get("api/chat", config);
  return response.data;
};

export const getTextToSpeechOfMessage = async (token, sessionId, messageId) => {
  const config = createReqConfig(token, sessionId);
  config["params"] = {
    message_id: messageId
  };
  config["responseType"] = "blob";
  const response = await Axios.get("api/chat/speech", config);

  return URL.createObjectURL(new Blob([response.data], { type: 'audio/mpeg' }));
};

export const getTrack = async (token, sessionId, trackId) => {
  const config = createReqConfig(token, sessionId);
  config["params"] = {
    track_id: trackId
  };
  const response = await Axios.get("api/track", config);
  return response.data;
};


export const getTrackRecommendations = async (token, sessionId) => {
  const config = createReqConfig(token, sessionId);
  const response = await Axios.get("api/track/recommendations", config);
  return response.data;
};

export const sendIMessageContactInfo = (token, sessionId, contactInfo) => {
  const config = createReqConfig(token, sessionId);
  return Axios.post("api/chat/imessage-connect", { "contact_info": contactInfo }, config);
};

export const getIntroOnboardingStatus = async (token, sessionId) => {
  const config = createReqConfig(token, sessionId);
  const response = await Axios.get("api/v1/profiles/me", config);
  return response.data;
}

export const updateIntroOnboardingStatus = (token, sessionId) => {
  const config = createReqConfig(token, sessionId);
  return Axios.patch("api/v1/profiles/me", { "metadata": { "intro_onboarded": true } }, config);
}