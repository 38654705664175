import React from "react";

const LoadingMessages = [
    "Let me put some thought into this",
    "Allow me to reflect on this for a moment",
    "Let me get creative and explore some paths for you",
    "allow a moment for me to formulate a soulful response to you",
    "Allow me to reflect your thoughts back to you under a new light",
    "Allow me to let your message unfold",
    "Give me a moment to draw from our wisdom library"
];

const getRandomLoadingMessage = () => (
  <div className="audio-mode__loading-message-container">
    <div className="audio-mode__loading-message">
      {LoadingMessages[Math.floor(Math.random() * LoadingMessages.length)]}
    </div>
  </div>
);

const HoldGuideMessage = {
  Mobile: "Touch & hold the Pearl to speak your mind",
  Desktop: "Hold spacebar to speak to the Pearl"
};

export const AudioModeState = {
  Ready: "READY",
  Recording: "RECORDING",
  Fetching: "FETCHING",
  ReadyToPlay: "READY_TO_PLAY",
  Playing: "PLAYING",
  Initiating: "INITIATING",
  UnrecoverableError: "ERROR",
  MicDisabled: "MIC_DISABLED"
};


export const getGuideMessage = ({ audioModeState, isDesktop, errorMessage }) => {
  switch (audioModeState) {
    case AudioModeState.Initiating:
      return errorMessage || "...loading...";

    case AudioModeState.Ready:
      return (
        <div className="audio-mode__guide-message">
          {errorMessage || (isDesktop ? HoldGuideMessage.Desktop : HoldGuideMessage.Mobile)}
        </div>
      );

    case AudioModeState.ReadyToPlay:
      return "";

    case AudioModeState.Playing:
      return "";

    case AudioModeState.Recording:
      return "...listening...";

    case AudioModeState.Fetching:
      return getRandomLoadingMessage();

    case AudioModeState.UnrecoverableError:
    case AudioModeState.MicDisabled:
      return (
        <div className="audio-mode__guide-message">
          {errorMessage}
        </div>
      );

    default: return "";
  }
};
