import React from 'react';
import cn from "classnames";

import './button.scss';

const Button = ({
  type,
  active = false,
  disabled = false,
  onClick,
  onPressDown,
  onLiftUp,
  children,
  disableContextMenu = false,
  variant,
  showBorder = true,
  size = 'medium',
  icon
}) => {
  const buttonClassNames = cn('button', {
    'button--active': active,
    'button--disabled': disabled,
    'button--primary': variant === 'primary',
    'button--primary-white': variant === 'primary-white',
    'button--secondary': variant === 'secondary',
    'button--secondary-white': variant === 'secondary-white',
    'button--warning': variant === 'warning',
    'button--no-border': !showBorder,
    'button--border': showBorder,
    'button--medium': size === 'medium',
    'button--large': size === 'large'
  });

  const handleContextMenu = (e) => {
    // Prevent the context menu only for the button
    if (disableContextMenu) {
      e.preventDefault();
    }
  };

  const Icon = icon ?? undefined;


  return (
    <button
      className={buttonClassNames}
      type={type}
      disabled={disabled}
      onClick={onClick}
      onMouseDown={onPressDown}
      onMouseUp={onLiftUp}
      onTouchStart={onPressDown}
      onTouchEnd={onLiftUp}
      onContextMenu={handleContextMenu}
    >
      { Icon && <div className='button__icon'><Icon /></div> }
      {children}
    </button>
  );
};

export default Button;
