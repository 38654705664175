import React from 'react';

import './textField.scss';

export const TextField = ({
    type,
    name,
    placeholder,
    value,
    onChange
}) => {
    return (
        <div className="text-field__input-container">
            <input className='text-field__input' type={type} name={name} placeholder={placeholder} value={value} onChange={event => onChange(event.target.value)} />
        </div>
    )
};