import React from 'react';
import { TourGuideParagraph } from '../TourGuideParagaph';

export const Segment9 = () => (
      <div>
        <TourGuideParagraph>
        Cultivate 'Pearls of Wisdom' from the garden within as you develop a new language for harmonious inner dialogue and peace of mind.
        </TourGuideParagraph>
        <TourGuideParagraph>
            <p>THE PEARL™ will not search the internet, check emails, or suggest recipes.</p>
            <p>Instead, THE PEARL™ is a recipe for health made of equal parts philosophy, psychology, therapy, science, art and technology for inner guidance.</p>
        </TourGuideParagraph>
        
    </div>
  );

