import React from 'react';

import './loadingDots.scss';

export const LoadingDots = () => (
    <div className="loading-dots">
        <div></div>
        <div></div>
        <div></div>
      </div>
);
