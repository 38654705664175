import React from "react";

import { Spinner as ReactstrapSpinner } from "reactstrap";

import './spinner.scss';

export const Spinner = () => (
  <div className="spinner">
    <ReactstrapSpinner className="spinner__icon" />
  </div>
);
