import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Col } from "reactstrap";
import { onChatWindowInitiated } from "../redux/chat/slice";
import { MessageContainer } from "../components/MessageContainer";
import { selectIsAuthLoading } from "../redux/auth/selectors";
import { AudioMode } from "../components/AudioMode";
import { selectResponseMode } from "../redux/ui/selectors";
import { ResponseMode } from "../constants";
import { selectIsInitiated } from "../redux/chat/selectors";

export const Chat = () => {
  document.body.classList.add('dark-theme');
  const isAuthLoading = useSelector(selectIsAuthLoading);
  const responseMode = useSelector(selectResponseMode);
  const isInitiated = useSelector(selectIsInitiated);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isAuthLoading && !isInitiated) dispatch(onChatWindowInitiated());
  }, [dispatch, isAuthLoading, isInitiated]);

  return (
    <Col className="chat-messages-container">
      {/* <AudioPanel /> */}
      {
        responseMode === ResponseMode.Text ? <MessageContainer /> : <AudioMode />
      }
    </Col>
  );
};

export default Chat;
