import { useMemo, useRef } from "react"
import { useDispatch, useSelector } from "react-redux";
import cn from "classnames";

import { onResponseModeToggled } from "../../redux/common/thunks";
import {
  selectIsFetching,
  selectIsInitiated,
} from "../../redux/chat/selectors";
import {
  selectToken,
  selectSessionId
} from "../../redux/auth/selectors";
import { useCallback, useEffect, useState } from "react";
import Button from "../common/Button";
import { AudioModeState, getGuideMessage } from "./utils";
import { Pulse } from "../../icons/Pulse";

import "./audioMode.scss";
import history from "../../utils/history";
import { ThumbPrint } from "../../icons/ThumbPrint";
import { getIsDesktopBrowser } from "../../utils/navigator";
import { sendVoice } from "../../api";
import { PearlLogo } from "../PearlLogo";

export const AudioMode = () => {
  const isDesktop = getIsDesktopBrowser();
  const dispatch = useDispatch();

  const [hasShownHoldGuideIcon, setHasShownHoldGuideIcon] = useState(false);
  const [isSpeechButtonPressed, setIsSpeechButtonPressed] = useState(false);
  const isSpeechButtonPressedRef = useRef(false);
  const speechButtonDebounceTimeout = useRef(null); 

  const [showPlayButton, setShowPlayButton] = useState(false);
  const [audioModeState, setAudioModeState] = useState(AudioModeState.Initiating);
  const [guideMessage, setGuideMessage] = useState(() => getGuideMessage({ audioModeState }));
  const [mediaRecorder, setMediaRecorder] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const isFetching = useSelector(selectIsFetching);
  const isChatInitiated = useSelector(selectIsInitiated);
  const token = useSelector(selectToken);
  const sessionId = useSelector(selectSessionId);

  const textmodeButtonText = "inner dialogue";

  useEffect(() => {
    if (navigator.permissions) {
      (async () => {
          const permissionStatus = await navigator.permissions.query({ name: 'microphone' });
          permissionStatus.onchange = function() {
            if (this.state === 'granted') {
              setErrorMessage("");
              setAudioModeState(AudioModeState.Ready);
            }
          }
      })();
    }
  }, []);

  const audioMimeType = useMemo(() => {
    if (!window.MediaRecorder) {
      console.error("No MediaRecorder API in browser - too old?");
      setErrorMessage("Sorry, it looks like your device does not support Audio Mode. Please use text mode.")
      setAudioModeState(AudioModeState.UnrecoverableError);
      return;
    }
    const availableTypes = ["audio/webm", "audio/mpeg", "video/mp4", "video/mpeg", "audio/wav"].filter(MediaRecorder.isTypeSupported)
    if (availableTypes.length === 0) {
      console.error("No available mime types for audio recording.");
      setErrorMessage("Sorry, it looks like your device does not support Audio Mode. Please use text mode.")
      setAudioModeState(AudioModeState.UnrecoverableError);
    }
    console.log("Using following mime type for recording: " + availableTypes);
    return availableTypes[0];
  }, []);

  useEffect(() => {
    setGuideMessage(getGuideMessage({ audioModeState, isDesktop, errorMessage }));
  }, [audioModeState, isDesktop, errorMessage]);

  useEffect(() => {
    if (audioModeState === AudioModeState.Initiating && isChatInitiated) {
      setAudioModeState(AudioModeState.Ready);
    }
  }, [isChatInitiated, audioModeState]);

  const handleOnAudioEnded = useCallback(() => {
    setAudioModeState(AudioModeState.Ready);
  }, []);

  const onSpeechButtonPressed = useCallback(async () => {
    if (speechButtonDebounceTimeout.current) {
      clearTimeout(speechButtonDebounceTimeout.current); // Clear existing timeout
    }

    isSpeechButtonPressedRef.current = true;

    speechButtonDebounceTimeout.current = setTimeout(async () => {
      const permissionStatus = await navigator.permissions.query({ name: 'microphone' });
      if (permissionStatus.state === 'granted') {
        if (!isSpeechButtonPressedRef.current) return;
        setIsSpeechButtonPressed(true);
        setHasShownHoldGuideIcon(true);
      } else if (permissionStatus.state === 'prompt') {
        try {
          const mediaStream = await navigator.mediaDevices.getUserMedia({ video: false, audio: true });
          mediaStream.getTracks().forEach((track) => track.stop());
        } catch {
          setErrorMessage('Please re-enable microphone access in your device settings to continue talking to the pearl.');
          setAudioModeState(AudioModeState.MicDisabled);
        }
      } else {
        setErrorMessage('Please re-enable microphone access in your device settings to continue talking to the pearl.');
        setAudioModeState(AudioModeState.MicDisabled);
      }
    }, 100);
  }, []);

  const onSpeechButtonLifted = useCallback(() => {
    setIsSpeechButtonPressed(false);
    isSpeechButtonPressedRef.current = false;

    if (speechButtonDebounceTimeout.current) {
      clearTimeout(speechButtonDebounceTimeout.current); // Clear timeout on mouse up
    }
  }, []);

  const handleOnKeyDown = useCallback((event) => {
    if (event.code === "Space" && !isSpeechButtonPressed) {
      onSpeechButtonPressed();
    }
  }, [onSpeechButtonPressed, isSpeechButtonPressed]);

  const handleOnKeyUp = useCallback((event) => {
    if (event.code === "Space") {
      onSpeechButtonLifted();
    }
  }, [onSpeechButtonLifted]);

  useEffect(() => {
    if (isDesktop) {
      document.addEventListener("keydown", handleOnKeyDown);
      document.addEventListener("keyup", handleOnKeyUp);
    }

    return () => {
      if (isDesktop) {
        document.removeEventListener("keydown", handleOnKeyDown);
        document.removeEventListener("keyup", handleOnKeyUp);
      } 
    }
  }, [isDesktop, handleOnKeyDown, handleOnKeyUp]);

  const onResponseModeIconToggled = () => {
    dispatch(onResponseModeToggled());
  };

  const handleOnPlayClicked = useCallback(() => {
    const audioElement = document.getElementById("audio-response");
    if (audioElement) {
      audioElement.play();
    }
    setAudioModeState(AudioModeState.Playing);
    setShowPlayButton(false);
  }, []);

  const onCloseClicked = () => {
    history.push('/home');
  };

  const canStream = () => {
    if (window.MediaSource) {
      return true;
    }
    return false;
  };

  const submitVoiceMessage = useCallback(async (recordedAudio) => {
    if(recordedAudio === null) {
      return;
    }
    setAudioModeState(AudioModeState.Fetching);
    const response = await sendVoice(token, sessionId, recordedAudio);
    if (response.status === 204) {
      setAudioModeState(AudioModeState.Ready);
      return;
    }
    if (!response.ok) {
      setErrorMessage("Sorry, there was an error connecting to the Pearl. Please try again.");
      setAudioModeState(AudioModeState.Ready);
      return;
    }
    setErrorMessage("");
    const audioElement = document.getElementById("audio-response");

    if (canStream()) {
      const reader = response.body.getReader();
      const mediaSource = new MediaSource();
      if (mediaSource === null) {
        setAudioModeState(AudioModeState.UnrecoverableError);
        console.log("Couldn't get Media Source.");
        return;
      }
      const url = URL.createObjectURL(mediaSource);
      audioElement.src = url;
      let played = false;

      async function handleSourceOpen() {
        // Create a new source buffer
        const sourceBuffer = mediaSource.addSourceBuffer('audio/mpeg');
        let prevAudioCurrentTime = undefined;

        const checkBufferState = () => {
          if (sourceBuffer.updating) {
            return setTimeout(checkBufferState, 500);
          }

          if (!sourceBuffer.updating && sourceBuffer.buffered.length > 0) {
              if (!prevAudioCurrentTime || audioElement.currentTime > prevAudioCurrentTime) {
                prevAudioCurrentTime = audioElement.currentTime;
                return setTimeout(checkBufferState, 500); // Check every second
              } else {
                  handleOnAudioEnded();
                  return;
              }
          }

          return;
        }
        while (true) {
          const {value, done} = await reader.read();
          if (done) { break; }
          await new Promise((resolve, reject) => {
            sourceBuffer.onupdate = (() => {
              resolve(true);
            });
            sourceBuffer.appendBuffer(value);
          }) 
          if (sourceBuffer.buffered.length > 0) {
            if (!played) {
              played = true;

              audioElement
                .play()
                .then(() => {
                  setAudioModeState(AudioModeState.Playing);
                })
                .catch(error => {
                  if (error.name === "NotAllowedError") {
                    setShowPlayButton(true);
                    setAudioModeState(AudioModeState.ReadyToPlay);
                  }
                });
            }
          }
        }

        checkBufferState();
      }

      mediaSource.addEventListener('sourceopen', handleSourceOpen);
    } else {
      console.log("Getting whole response instead of streaming")
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      audioElement.src = url;
      audioElement
        .play()
        .then(() => {
          setAudioModeState(AudioModeState.Playing);
        })
        .catch(error => {
          if (error.name === "NotAllowedError") {
            setShowPlayButton(true);
            setAudioModeState(AudioModeState.ReadyToPlay);
          }
        });
    }
  }, [handleOnAudioEnded, sessionId, token]);

  useEffect(() => {
    if (isSpeechButtonPressed && audioModeState === AudioModeState.Ready) {
      setAudioModeState(AudioModeState.Recording);

      navigator.mediaDevices.getUserMedia({ video: false, audio: true }).then(
        (stream) => {
          // Sometimes the prompt to access the microphone messes up this flow. Play it safe.
          if (!audioModeState === AudioModeState.Recording) {
            return;
          }
          let audioChunks = [];
          const onDataAvailable = (e) => {
            audioChunks.push(e.data);
          };
          const onMediaRecorderStop = (e) => {
            const blob = new Blob(audioChunks, {type: mediaRecorder.mimeType});
            stream.getTracks().forEach(track=>{track.stop()}) 
            setMediaRecorder(null);
            submitVoiceMessage(blob);
          };
          const mediaRecorder = new MediaRecorder(stream, { mimeType: audioMimeType, audioBitsPerSecond : 32000 })
          mediaRecorder.ondataavailable = onDataAvailable;
          mediaRecorder.onstop = onMediaRecorderStop;
          mediaRecorder.start(1000);
          setMediaRecorder(mediaRecorder);
        },
        (error) => {
          console.error(error);
          setAudioModeState(AudioModeState.UnrecoverableError);
        }
      );
    } else if (!isSpeechButtonPressed && audioModeState == AudioModeState.Recording) {
      if (!mediaRecorder) {
        setAudioModeState(AudioModeState.Ready);
        return;
      }
      mediaRecorder.stop();
    }
  }, [isSpeechButtonPressed, submitVoiceMessage, audioModeState, mediaRecorder, audioMimeType]);

  useEffect(() => {
    window.addEventListener('mouseup', onSpeechButtonLifted);

    return () => {
      window.removeEventListener('mouseup', onSpeechButtonLifted);
    };
  }, [onSpeechButtonLifted]);

  return (
    <div className="audio-mode">
      <div className="audio-mode__top-bar">
        <div className="audio-mode__close-button-container">
          <Button onClick={onCloseClicked} variant="primary" size="large">
            <p className="audio-mode__close-button">X</p>
          </Button>
        </div>
      </div>
      <div className="audio-mode__container">
        <div className="audio-mode__guide-text">
          {
            showPlayButton ? (
              <div className="audio-mode__play-button">
                <Button disableContextMenu variant="primary-white" onClick={handleOnPlayClicked} size="large">
                  Play
                </Button>
              </div>
            ) 
            : guideMessage
          }
        </div>
        <div className="audio-mode__icon-section">
          <div className={
            cn("audio-mode-overlay__icon", {
              "audio-mode-overlay__icon--disabled": isFetching
            })
          }>
            <Button
              onPressDown={onSpeechButtonPressed}
              onLiftUp={onSpeechButtonLifted}
              disabled={isDesktop || ![AudioModeState.Ready, AudioModeState.Recording].includes(audioModeState)}
              disableContextMenu
              size="large"
            >
              <audio
                id="audio-response"
                onEnded={handleOnAudioEnded}
                hidden
              />
                <PearlLogo className="audio-mode-overlay__pearl-logo"/>
                {(audioModeState === AudioModeState.Playing) && (
                <div className="audio-mode-overlay__icon-pulse">
                  <Pulse size="250px" />
                </div>
              )}
            </Button>
            {
              !isDesktop &&
              (
                <div
                  className={cn("audio-mode__hold-guide-icon", {
                    "audio-mode__hold-guide-icon--hidden": hasShownHoldGuideIcon || ![AudioModeState.Ready, AudioModeState.Initiating].includes(audioModeState),
                  })}
                >
                  <ThumbPrint />
                </div>
              )
            }
          </div>
        </div>
        <div className="audio-mode__footer">
          <Button disabled={![AudioModeState.Ready, AudioModeState.Initiating, AudioModeState.UnrecoverableError, AudioModeState.MicDisabled].includes(audioModeState)} onClick={onResponseModeIconToggled} variant="primary-white" size="large">
            {textmodeButtonText}
          </Button>
        </div>
      </div>
    </div>
  );
};
