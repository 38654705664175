import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getMessageHistory, sendMessageText } from "../../api";
import { onResponseModeToggled } from '../common/thunks';

export const onChatWindowInitiated = createAsyncThunk(
  "chat/onChatWindowInitiated",
  async (_, { getState }) => {
    const state = getState();
    const token = state.auth.token;
    const sessionId = state.auth.sessionId;
    const messageHistory = await getMessageHistory(token, sessionId, null);
    return messageHistory;
  }
);

export const onNewTextMessageSubmitted = createAsyncThunk(
  "chat/onNewTextMessageSubmitted",
  async (message, { getState }) => {
    let messageTrimmed = message.trim();
    if (messageTrimmed.length < 1) {
      return [];
    }
    const state = getState();
    const token = state.auth.token;
    const sessionId = state.auth.sessionId;
    const messages = await sendMessageText(token, sessionId, messageTrimmed);
    return messages;
  }
);

export const onRequestMoreConversationHistory = createAsyncThunk(
  "chat/onRequestMoreConversationHistory",
  async (_, { getState }) => {
    const state = getState();
    const token = state.auth.token;
    const sessionId = state.auth.sessionId;
    const messages = state.chat.messages;
    const oldestMessageId = messages[0]?.id ?? null;
    const messageHistory = await getMessageHistory(token, sessionId, oldestMessageId);
    return messageHistory;
  }
);

const chatSlice = createSlice({
  name: "chat",
  initialState: {
    isInitiated: false,
    messages: [],
    isFetching: false,
    isFetchingMoreHistory: false,
    hasUnloadedHistory: true,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(onChatWindowInitiated.pending, (state) => {
      state.isFetching = true;
    });

    builder.addCase(onChatWindowInitiated.fulfilled, (state, { payload }) => {
      state.isInitiated = true;
      state.messages = payload.map(({ _id, content, source, track_id }) => ({
        id: _id,
        content,
        source,
        trackId: track_id,
      }));
      state.isFetching = false;
    });

    builder.addCase(
      onNewTextMessageSubmitted.pending,
      (state, { meta: { arg } }) => {
        state.messages.push({
          id: "TEMP_ID",
          source: "user",
          content: arg,
        });
        state.isFetching = true;
      }
    );
    builder.addCase(onNewTextMessageSubmitted.fulfilled, (state, action) => {
      state.messages.pop();
      action.payload.forEach(({ _id, content, source, track_id }) => {
        state.messages.push({
          id: _id,
          content,
          source,
          trackId: track_id,
        });
      });

      state.isFetching = false;
    });
    builder.addCase(onRequestMoreConversationHistory.pending, (state) => {
      state.isFetchingMoreHistory = true;
    });
    builder.addCase(onRequestMoreConversationHistory.fulfilled, (state, { payload }) => {
      if (!payload.length) {
        state.hasUnloadedHistory = false;
      }

      state.messages = [...payload.map(({ _id, content, source, track_id }) => ({
        id: _id,
        content,
        source,
        trackId: track_id,
      })), ...state.messages];
      state.isFetchingMoreHistory = false;
    });

    builder.addCase(onResponseModeToggled.pending, (state, { payload }) => {
      state.isFetching = true;
    });

    builder.addCase(onResponseModeToggled.fulfilled, (state, { payload }) => {
      state.isFetching = false;
      if (!payload) return;

      state.messages = payload.map(({ _id, content, source, track_id }) => ({
        id: _id,
        content,
        source,
        trackId: track_id,
      }));

    });
  },
});

export default chatSlice.reducer;
