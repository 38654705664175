import { createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from 'js-cookie';
import { Cookie, ResponseMode, SiteAuth, TourGuideCompletion } from "../../constants";
import { getMessageHistory } from "../../api";

export const onResponseModeToggled = createAsyncThunk(
    "common/onResponseModeToggled",
    async (_, { getState }) => {
        const state = getState();
        const toggledToTextMode = state.ui.responseMode === ResponseMode.Text;
        if (toggledToTextMode) {
            const token = state.auth.token;
            const sessionId = state.auth.sessionId;
            return await getMessageHistory(token, sessionId, null);
        }

        return undefined;
    }
);

export const onAppInit = createAsyncThunk(
    "common/onAppInit",
    () => {
        const siteAuthCookie = Cookies.get(Cookie.SiteAuth);
        const tourGuideCookie = Cookies.get(Cookie.HasCompletedTourGude);

        return {
            sitePasswordValidated: siteAuthCookie === SiteAuth.isAuthenticated,
            hasCompletedTourGuide: tourGuideCookie === TourGuideCompletion.Completed
        }
    }
);
